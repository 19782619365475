import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  user: null,
  users: null,
  unionMembers: null,
  loading: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setUnionMembers: (state, action) => {
      state.unionMembers = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = true;
    },
  },
});

// Get all Users
export const getUsersAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "api/user"
    );
    dispatch(setUsers(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

// Get users by union
export const getUsersByUnionAsync = (union_id) => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL +
        "api/user/get_union_members/" +
        union_id
    );
    dispatch(setUnionMembers(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

// Get users by role
export const getUsersByRoleAsync = (type) => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "api/user/get_users_by_role/" + type
    );
    dispatch(setUsers(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

// Get a user profile
export const getUserAsync = (user_id) => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "api/user/" + user_id
    );
    dispatch(setUser(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const { setUser, setUsers, setUnionMembers, setLoading } =
  userSlice.actions;
export const showUsers = (state) => state.user.users;
export const showUser = (state) => state.user.user;
export const showUnionMembers = (state) => state.user.unionMembers;
export const showLoading = (state) => state.user.loading;
export default userSlice.reducer;
