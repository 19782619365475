import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  job: null,
  jobs: null,
  assignedJobs: null,
  position: ["Deckhand", "Terminal Attendant", "Vessel Master", "Supervisor"],
  service: ["Oakland/Alameda"],
  location: [
    "Larkspur Ferry Terminal",
    "San Francisco Ferry Terminal",
    "Sausalito",
    "AT & T",
    "Call Supervisor for Location Start",
  ],
  duration: [
    {
      label: "8 Hours",
      value: 8,
    },
    {
      label: "16 Hours",
      value: 16,
    },
    {
      label: "24 Hours",
      value: 24,
    },
    {
      label: "36 Hours",
      value: 36,
    },
    {
      label: "40 Hours",
      value: 40,
    },
  ],
  qualifications: [
    "Valid Driver's Licence",
    "Respirator Qualified",
    "Ramp Qualified",
    "Lead",
  ],
  loading: false,
};

export const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    setJob: (state, action) => {
      state.job = action.payload;
    },
    setJobs: (state, action) => {
      state.jobs = action.payload;
    },
    setAssignedJobs: (state, action) => {
      state.assignedJobs = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = true;
    },
  },
});

// Get all Jobs
export const getJobsAsync = () => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "api/job"
    );
    dispatch(setJobs(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

// Get jobs by company
export const getJobsByCompanyAsync = (companyId) => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL +
        "api/job/get_company_jobs/" +
        companyId
    );
    dispatch(setJobs(response.data.data || []));
  } catch (err) {
    dispatch(setJobs([]));
    throw new Error(err);
  }
};

// Get unassigned jobs
export const getUnAssignedJobsAsync = (companyId) => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL +
        "api/job/get_unassigned_jobs/" +
        companyId
    );
    dispatch(setJobs(response.data.data || []));
  } catch (err) {
    dispatch(setJobs([]));
    throw new Error(err);
  }
};

// Get unassigned jobs
export const getAssignedJobsAsync = (unionId) => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL +
        "api/job/get_assigned_jobs/" +
        unionId
    );
    dispatch(setAssignedJobs(response.data.data || []));
  } catch (err) {
    dispatch(setAssignedJobs([]));
    throw new Error(err);
  }
};

// Get a job details
export const getJobAsync = (job_id) => async (dispatch) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "api/job/" + job_id
    );
    dispatch(setJob(response.data));
  } catch (err) {
    throw new Error(err);
  }
};

export const { setJob, setJobs, setAssignedJobs, setLoading } =
  jobSlice.actions;
export const showJob = (state) => state.job.job;
export const showJobs = (state) => state.job.jobs;
export const showAssignedJobs = (state) => state.job.assignedJobs;
export const showPosition = (state) => state.job.position;
export const showService = (state) => state.job.service;
export const showLocation = (state) => state.job.location;
export const showDuration = (state) => state.job.duration;
export const showQualifications = (state) => state.job.qualifications;
export const showLoading = (state) => state.job.loading;
export default jobSlice.reducer;
