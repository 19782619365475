import React, { lazy, Suspense, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import PrivateRoute from "utils/privateRoute";
import setAuthToken from "utils/setAuthToken";
import { setCurrentUser } from "app/authSlice";
import { jwtDecode as jwt_decode } from "jwt-decode";
import { useDispatch } from "react-redux";
import { logoutUser } from "app/authSlice";
import "App.css";

// Import components
const HomePage = lazy(() => import("pages/HomePage"));
const LoginPage = lazy(() => import("features/LoginPage"));
const RegisterPage = lazy(() => import("features/RegisterPage"));
const ProfileComplete = lazy(() => import("features/ProfileComplete"));
const TokenInput = lazy(() => import("features/TokenInput"));

// Dashboard
const Dashboard = lazy(() => import("pages/dashboard/index"));
const UserManagement = lazy(() => import("pages/dashboard/UserManagement"));
const Profile = lazy(() => import("pages/dashboard/Profile"));
const Settings = lazy(() => import("pages/dashboard/Settings"));

// Dispatch module
const DispatchDashboard = lazy(() => import("pages/modules/dispatch/index"));
const DispatchJobPostings = lazy(() =>
  import("pages/modules/dispatch/JobPostings")
);
const DispatchJobDetails = lazy(() =>
  import("pages/modules/dispatch/JobDetails")
);
const DispatchJobAssignment = lazy(() =>
  import("pages/modules/dispatch/JobAssignment")
);
const DispatchJobAssignmentsLogs = lazy(() =>
  import("pages/modules/dispatch/JobAssignmentLogs")
);
const DispatchJobBidding = lazy(() =>
  import("pages/modules/dispatch/JobBidding")
);
const DispatchMemberRecords = lazy(() =>
  import("pages/modules/dispatch/MemberRecords")
);
const DispatchNonDispatchList = lazy(() =>
  import("pages/modules/dispatch/NonDispatchList")
);
const DispatchReports = lazy(() => import("pages/modules/dispatch/Reports"));
const DispatchSettings = lazy(() => import("pages/modules/dispatch/Settings"));
const DispatchStandbyList = lazy(() =>
  import("pages/modules/dispatch/StandbyList")
);
const DispatchDisputes = lazy(() => import("pages/modules/dispatch/Disputes"));
const DispatchUnionSeniority = lazy(() =>
  import("pages/modules/dispatch/UnionSeniority")
);
const DispatchCompanySeniority = lazy(() =>
  import("pages/modules/dispatch/CompanySeniority")
);
const DispatchUnionShippingList = lazy(() =>
  import("pages/modules/dispatch/UnionShippingList")
);
const DispatchCompanyShippingList = lazy(() =>
  import("pages/modules/dispatch/CompanyShippingList")
);
const DispatchMemberManagement = lazy(() =>
  import("pages/modules/dispatch/MemberManagement")
);
const DispatchUserProfile = lazy(() =>
  import("pages/modules/dispatch/UserProfile")
);

//  Credentials module
const CertificationDashboard = lazy(() =>
  import("pages/modules/credentials/index")
);
const CertificationUpload = lazy(() =>
  import("pages/modules/credentials/CertificationUpload")
);
const CertificationDetails = lazy(() =>
  import("pages/modules/credentials/CertificationDetails")
);
const CertificationReminders = lazy(() =>
  import("pages/modules/credentials/CertificationReminders")
);
const CertificationRenewal = lazy(() =>
  import("pages/modules/credentials/CertificationRenewal")
);
const CertificationSubscription = lazy(() =>
  import("pages/modules/credentials/CertificationSubscription")
);

// Weather module
const WeatherDashboard = lazy(() => import("pages/modules/weather/index"));
const WeatherAlerts = lazy(() => import("pages/modules/weather/WeatherAlerts"));
const WeatherSafetyReminders = lazy(() =>
  import("pages/modules/weather/SafetyReminders")
);
const WeatherHistoricalWeather = lazy(() =>
  import("pages/modules/weather/HistoricalWeather")
);

// Development
const DevelopmentFormPage = lazy(() => import("development/pages/Forms"));
const DevelopmentDashboardPage = lazy(() =>
  import("development/pages/Dashboard")
);

// Preloader
const renderLoader = () => <p></p>;

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.jwtToken) {
      setAuthToken(localStorage.jwtToken);
      const decoded = jwt_decode(localStorage.jwtToken);
      dispatch(setCurrentUser(decoded));
      // Check for expired token
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        dispatch(logoutUser());
        navigate("/");
      }
    }
  }, []);

  return (
    <Suspense fallback={renderLoader()}>
      <div className="App">
        <Toaster position="top-right" />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route
            path="/profile-complete"
            element={
              <PrivateRoute>
                <ProfileComplete />
              </PrivateRoute>
            }
          />
          <Route
            path="/token-input"
            element={
              <PrivateRoute>
                <TokenInput />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/user-management"
            element={
              <PrivateRoute>
                <UserManagement />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile/:id"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />

          {/* Dispatch Module */}

          <Route
            path="/dispatch"
            element={
              <PrivateRoute tokenRequired={true}>
                <DispatchDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/dispatch/job-postings"
            element={
              <PrivateRoute tokenRequired={true}>
                <DispatchJobPostings />
              </PrivateRoute>
            }
          />
          <Route
            path="/dispatch/job-details/:id"
            element={
              <PrivateRoute tokenRequired={true}>
                <DispatchJobDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/dispatch/job-assignment"
            element={
              <PrivateRoute tokenRequired={true}>
                <DispatchJobAssignment />
              </PrivateRoute>
            }
          />
          <Route
            path="/dispatch/job-assignment-logs"
            element={
              <PrivateRoute tokenRequired={true}>
                <DispatchJobAssignmentsLogs />
              </PrivateRoute>
            }
          />
          <Route
            path="/dispatch/job-bidding"
            element={
              <PrivateRoute tokenRequired={true}>
                <DispatchJobBidding />
              </PrivateRoute>
            }
          />
          <Route
            path="/dispatch/member-records"
            element={
              <PrivateRoute tokenRequired={true}>
                <DispatchMemberRecords />
              </PrivateRoute>
            }
          />
          <Route
            path="/dispatch/non-dispatch-list"
            element={
              <PrivateRoute tokenRequired={true}>
                <DispatchNonDispatchList />
              </PrivateRoute>
            }
          />
          <Route
            path="/dispatch/reports"
            element={
              <PrivateRoute tokenRequired={true}>
                <DispatchReports />
              </PrivateRoute>
            }
          />
          <Route
            path="/dispatch/settings"
            element={
              <PrivateRoute tokenRequired={true}>
                <DispatchSettings />
              </PrivateRoute>
            }
          />
          <Route
            path="/dispatch/disputes"
            element={
              <PrivateRoute tokenRequired={true}>
                <DispatchDisputes />
              </PrivateRoute>
            }
          />
          <Route
            path="/dispatch/standby-list"
            element={
              <PrivateRoute tokenRequired={true}>
                <DispatchStandbyList />
              </PrivateRoute>
            }
          />
          <Route
            path="/dispatch/union-seniority"
            element={
              <PrivateRoute tokenRequired={true}>
                <DispatchUnionSeniority />
              </PrivateRoute>
            }
          />
          <Route
            path="/dispatch/company-seniority"
            element={
              <PrivateRoute tokenRequired={true}>
                <DispatchCompanySeniority />
              </PrivateRoute>
            }
          />
          <Route
            path="/dispatch/union-shipping-list"
            element={
              <PrivateRoute tokenRequired={true}>
                <DispatchUnionShippingList />
              </PrivateRoute>
            }
          />
          <Route
            path="/dispatch/company-shipping-list"
            element={
              <PrivateRoute tokenRequired={true}>
                <DispatchCompanyShippingList />
              </PrivateRoute>
            }
          />
          <Route
            path="/dispatch/member-management"
            element={
              <PrivateRoute tokenRequired={true}>
                <DispatchMemberManagement />
              </PrivateRoute>
            }
          />
          <Route
            path="/dispatch/user-profile/:id"
            element={
              <PrivateRoute tokenRequired={true}>
                <DispatchUserProfile />
              </PrivateRoute>
            }
          />

          {/* Credentials Module */}

          <Route path="/credentials" element={<CertificationDashboard />} />
          <Route
            path="/credentials/certification-upload"
            element={<CertificationUpload />}
          />
          <Route
            path="/credentials/certification-details/:id"
            element={<CertificationDetails />}
          />
          <Route
            path="/credentials/certification-reminders"
            element={<CertificationReminders />}
          />
          <Route
            path="/credentials/certification-renewal"
            element={<CertificationRenewal />}
          />
          <Route
            path="/credentials/certification-subscription"
            element={<CertificationSubscription />}
          />

          {/* Weather Module */}

          <Route path="/weather" element={<WeatherDashboard />} />
          <Route path="/weather/alerts" element={<WeatherAlerts />} />
          <Route
            path="/weather/safety-reminders"
            element={<WeatherSafetyReminders />}
          />
          <Route
            path="/weather/historical-weather"
            element={<WeatherHistoricalWeather />}
          />

          {/* Development */}
          <Route path="/development" element={<DevelopmentDashboardPage />} />
          <Route path="/development/forms" element={<DevelopmentFormPage />} />
        </Routes>
      </div>
    </Suspense>
  );
}

export default App;
